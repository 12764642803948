// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 3;

// Bootstrap variables
@import 'common/variables';

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_normalize.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_type.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_tables.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_forms.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_button-groups.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_input-groups.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_buttons.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_component-animations.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_dropdowns.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_navs.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_navbar.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_pagination.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_labels.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_alerts.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_responsive-embed.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_close.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_modals.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_wells.scss';
// endbower

@import 'common/global';
@import 'components/comments';
@import 'components/forms';
@import 'components/wp-classes';
@import 'layouts/header';
@import 'layouts/sidebar';
@import 'layouts/footer';
@import 'layouts/pages';
@import 'layouts/posts';
@import 'layouts/tinymce';
@import 'layouts/map';

.container-fluid
{
    max-width: $screen-lg;
    margin-right: auto;
    margin-left: auto;

    background-color: #fff;
}

.well
{
    &.error
    {
        color: #fff;
        background-color: $brand-danger;
    }
    &.success
    {
        color: #fff;
        background-color: $brand-success;
    }
}

i
{
    height: 0;

    @include opacity(0);
}
html.wf-active
{
    i
    {
        height: auto;

        @include opacity(1);
    }
}

.material-icons
{
    font-size: inherit;
    line-height: inherit;

    display: inline-block;

    vertical-align: bottom;

    text-rendering: auto;
}

.material-icons.md-18
{
    font-size: 18px;
}
.material-icons.md-24
{
    font-size: 24px;
}
.material-icons.md-36
{
    font-size: 36px;
}
.material-icons.md-48
{
    font-size: 48px;
}

.material-icons.md-dark
{
    color: rgba($brand-warning, 1);
}
.material-icons.md-dark.md-inactive
{
    color: rgba($brand-warning, .25);
}

.material-icons.md-light
{
    color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive
{
    color: rgba(255, 255, 255, .3);
}

.navbar
{
    font-family: $headings-font-family;
}

@media (min-width: $screen-sm)
{
    .navbar-collapse > .navbar-nav
    {
        float: right;
    }
}

@media (max-width: $screen-xs-max) {
    .brand img {
        max-width:60% !important;
    }
}

.btn
{
    font-family: $headings-font-family;

    text-transform: uppercase;
}

.special-bar
{
    padding: 20px 20px 0;

    color: #fff;
    background-color: #efb924;

    a
    {
        color: #fff;
    }
}

.crumb
{
    margin-bottom: 20px;
    a
    {
        color: #bc911d;
    }
}

i.audio[data-mp3]
{
    cursor: pointer;
}

ul#sidebar-menu > li
{
    & > ul
    {
        display: none;
    }

    &.active > ul
    {
        display: block;
    }
}

.widget
{
    margin-bottom: 20px;
}

.nav-pills
{
    > li
    {
        text-transform: uppercase;

        background-color: $gray-lighter;
        a
        {
            color: $gray;
            &:hover
            {
                color: #fff;
            }
            &.alert
            {
                margin: 0;

                color: #fff;
                &:hover
                {
                    background-color: lighten($brand-danger, 5%);
                }
            }
        }
        > ul
        {
            list-style-type: none;
            > li
            {
                padding: 10px 0;
                a:hover
                {
                    color: $gray;
                }
            }
        }
    }
}

.nav-tabs
{
    > li
    {
        > a
        {
            font-size: $font-size-h3;

            color: $text-color;
            border-radius: 0;
        }
    }
}

.ribbon-wrapper
{
    position: absolute;
    top: -10px;
    right: -50px;

    overflow: hidden;

    width: 250px;
    height: 135px;
}
.ribbon
{
    font-size: $font-size-h5;
    font-weight: bold;

    position: relative;
    z-index: 2;
    top: 30px;
    left: 1px;

    width: 250px;
    padding: 7px 0;

    transform: rotate(25deg);
    text-align: center;

    color: #fff;

    @include gradient-vertical(darken($brand-danger, 10%), $brand-danger);
}

.page-header
{
    margin: 0 0 20px;
}

#googlemap
{
    min-height: 150px;
    margin-bottom: 10px;
}
*[class^='col-'] .img-responsive
{
    width: 100%;
}
