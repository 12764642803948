.active-map
{
  position: relative;

  width: 320px;
  height: 375px;
  margin: 0 auto;

  list-style: none;

  background: url('../images/dk.png');

  a
  {
    position: absolute;

    display: block;

    cursor: pointer;

    &:hover
    {
      background: url('../images/dk.png');
    }
  }

  span
  {
    position: absolute;

    display: block;

    cursor: pointer;

    &.active
    {
      background: url('../images/dk.png');
    }
  }

  .nordjylland
  {
    top: 8px;
    left: 21px;

    width: 188px;
    height: 139px;

    &:hover,
    &.active
    {
      background-position: -126px -687px;
    }
  }

  .vestjylland
  {
    top: 111px;
    left: 9px;

    width: 120px;
    height: 122px;

    &:hover,
    &.active
    {
      background-position: -177px -544px;
    }
  }

  .ostjylland
  {
    top: 122px;
    left: 85px;

    width: 113px;
    height: 123px;

    &:hover,
    &.active
    {
      background-position: -6px -687px;
    }
  }

  .sydjylland
  {
    top: 194px;
    left: 6px;

    width: 129px;
    height: 147px;

    &:hover,
    &.active
    {
      background-position: -4px -528px;
    }
  }

  .fyn
  {
    top: 246px;
    left: 106px;

    width: 85px;
    height: 107px;

    &:hover,
    &.active
    {
      background-position: -11px -848px;
    }
  }

  .nordsjalland
  {
    top: 190px;
    right: 21px;

    width: 101px;
    height: 103px;

    &:hover,
    &.active
    {
      background-position: -2px -376px;
    }
  }

  .sydsjalland
  {
    top: 232px;
    right: 24px;

    width: 110px;
    height: 140px;

    &:hover,
    &.active
    {
      background-position: -122px -380px;
    }
  }

  .copenhagen
  {
    top: 220px;
    right: 9px;

    width: 45px;
    height: 40px;

    &:hover,
    &.active
    {
      background-position: -256px -420px;
    }
  }

  .bornholm
  {
    top: 79px;
    right: 23px;

    width: 37px;
    height: 40px;

    &:hover,
    &.active
    {
      background-position: -146px -882px;
    }
  }
}

.frontpage-map
{
  position: relative;

  .map-arrow
  {
    position: absolute;
    top: 50px;
    left: 50%;

    width: 44px;
    height: 99px;
    margin-left: -200px;

    background: url('../images/dk.png');
    background-position: -247px -852px;
  }
}